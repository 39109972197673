<template>
  <div class="page-box">
    <div class="page-bg">
      <img src="~@/assets/images/order/order_bg.png" alt="">
    </div>
    <div class="page-title">
      <div class="icon">
        <img src="~@/assets/images/order/order_icon.svg" width="100%" alt="">
      </div>
      <div class="info">
        <div class="title">预约成功</div>
        <div class="desc">服务预约工单已提交</div>
      </div>
    </div>
    <div class="page-main">
      <div class="block">
        <div class="block-header">邀请您添加VIP客服，享受一对一服务管家接待！</div>
        <div class="block-main">
          <div class="photo">
            <img src="~@/assets/images/order/service_qr.jpg" width="100%" alt="">
          </div>
          <div class="tips">点击图片长按识别二维码，添加老板企业微信，升级一对一专属服务管家接待！</div>
        </div>
        <router-link class="flex-center link-btn" to="/order/submit">
          <span>点击查看服务进度</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {

      }
    }
  }
</script>

<style lang="less" scoped>
  @import './style.less';
</style>